.contact-container {
  width: 60%;
  margin-left: 20%;
  padding-top: 180px;
  margin-top: -160px;
}

.contact-card {
  width: 100%;
  height: 200px;
  margin-top: 10px;
  border: 1px solid lightgray;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.contact-button {
  margin-right: 30px;
  text-decoration: none;
  color: #0573e1;
  cursor: pointer;
}

@media screen and (max-width:600px) {
  .contact-container {
    width: 90%;
    margin-left: 5%;
  }
}

@media (prefers-color-scheme: dark) {
  .contact-card {
    background-color: #2b2b2e;
    border-color: #2b2b2e;  
  }

  .contact-button {
    color: #368fe7;
  }
}