.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  background-color: #eee;
  position: relative;
}

.page {
  padding-bottom: 80px;
}

@media screen and (max-width:600px) {
  .page {
    margin-top: 0px;
    padding-top: 5px;
  }
}