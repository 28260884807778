.posts-loading-container {
    width: 60%;
    margin-left: 20%;
    padding-top: 180px;
    margin-top: -160px;
}

.posts-section {
    width: 60%;
    margin-left: 20%;
    padding-top: 20px;
}

.posts-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.post-card {
    height: 200px;
    border: 1px solid lightgray;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background-color: white;
}

.post-labels {
    width: 100%;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    flex-direction: row;
}

.post-image {
    height: 160px;
    margin: 20px;
    border-radius: 20px;
}

.post-data-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.post-title {
    width: 90%;
    margin: 0px;
    margin-top: 20px;
    margin-left: 30px;
    text-align: left;
    font-size: 2em;
    color: #333;
}

.post-description {
    width: 90%;
    margin-top: 10px;
    margin-left: 30px;
    text-align: justify;
    font-size: 1.25em;
    line-height: 24px;
    color: #555;
}

.post-data {
    width: 100%;
    margin-top: 5px;
    margin-left: 30px;
    display: flex;
    gap: 10px;
    font-size: 1em;
    color: #444;

}

.post-card-plus {
    min-height: 100px;
    border: 1px solid lightgray;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
}

.post-plus {
    color: #0573e1;
    font-size: 150px;
    margin-top: -20px;
    font-weight: bold;
}

.post-plus-text {
    color: #0573e1;
    font-size: 20px;
}
  
@media screen and (max-width:600px) {
    .posts-section {
      width: 100%;
      margin-left: 0%;
      padding-top: 180px;
      margin-top: -160px;
    }
  
    .posts-container {
      width: 90%;
      margin-left: 5%;
      grid-template-columns: repeat(1, 1fr);
    }

    .post-card {
        height: auto;
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        flex-direction: column;
    }

    .post-image {
        width: 90%;
        margin-left: 5%;
        height: auto;
    }
    
    .post-title {
        width: 90%;
        margin-left: 5%;
        margin-top: -10px;
        font-size: 1.5em;
    }
    .post-description {
        width: 90%;
        margin-left: 5%;
        margin-top: 5px;
        font-size: 1em;
    }
    
    .post-data {
        width: 90%;
        margin-left: 5%;    
    }
}

@media (prefers-color-scheme: dark) {
    .post-card {
        background-color: #2b2b2e;
        border-color: #2b2b2e;
    }

    .post-title {
        color: #dadada;
    }

    .post-description {
        color: #dadada;
    }

    .post-data {
        color: #dadada;
    }

    .post-card-plus {
        background-color: #2b2b2e;
        border-color: #2b2b2e;
    }
    
    .post-plus {
        color: #368fe7;
    }
    
    .post-plus-text {
        color: #368fe7;
    }
}