.navbar {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid lightgrey;
  background-color: white;
  z-index: 5;
  text-align: center;
  vertical-align: middle;
}

.navbar-list {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  float: right;
  margin-right: 20%;
  display: flex;
  flex-direction: row;
}

.navbar-option {
  margin-left: 20px;
}

.navbar-option a {
  text-decoration: none;
  color: #222;
}

.navbar-option-active a {
  color: #0573e1;
}

.close-nav-bar {
  visibility: hidden;
}

@media screen and (max-width:600px) {
  .navbar {
    height: auto;
    visibility: hidden;
    text-align: left;
    position: fixed;
  }

  .navbar-show {
    visibility: visible;
  }

  .navbar-list {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    top:0;
    transform: translateY(0%);
    float: left;
    display: flex;
    flex-direction: column;
  }

  .navbar-option {
    margin-top: 10px;
  }

  .open-nav-bar {
    border: 1px solid lightgrey;
  }

  .close-nav-bar {
    visibility: visible;
    position: absolute;
    top: -20px;
    right: 25px;
    padding: 10px;
    font-size: 30px;
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
  }
}

@media (prefers-color-scheme: dark) {
  .navbar {
    background-color: #2b2b2e;
    border-color: #2b2b2e;
  }

  .navbar-option > a{
    color: #dadada;
  }

  .navbar-option{
    background-color: #2b2b2e;
    border-color: #2b2b2e;
    color: #dadada;
  }
  
  .navbar-option-active > a{
    color: #0573e1;
  }
}