.post-loading-container {
    width: 60%;
    margin-left: 20%;
    padding-top: 180px;
    margin-top: -160px;
}

.header_image_container {
    width: 60%;
    height: auto;
    margin-left: 20%;
}

.header_image {
    width: 100%;
    border-radius: 0px 0px 20px 20px;
}

.title_container {
    width: 60%;
    margin-left: 20%;
    margin-top: 50px;
    text-align: center;
}

.title_container > h1 {
    font-size: 3.5em;
    color: #333;
    margin-bottom: 10px;
}

.data_container {
    width: 60%;
    margin-left: 20%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 10px;
    color: #444;
}

.data_container > span {
    font-size: 1.2em;
    color: #444;
}

.content_container {
    width: 40%;
    margin-left: 30%;
    margin-top: 50px;
}

.content_container > p {
    font-size: 1.25em;
    text-align: justify;
    color: #222;
}

.content_container > h2 {
    font-size: 1.8em;
    color: #333;
}

.content_container > h3 {
    font-size: 1.5em;
    color: #333;
}

.content_container > img {
    max-width: 90%;
    display:block;
    margin:auto;
    border-radius: 20px;
    cursor: pointer;
}

.author_container {
    width: 40%;
    margin-left: 30%;
    margin-top: 30px;
    color: #444;
    font-size: 1.8em;
    font-family: Satisfy
}

@media screen and (max-width:600px) {
    .header_image_container {
        height: auto;
        width: 100%;
        margin-left: 0;
        margin-top: -5px;
    }

    .header_image {
        width: 100%;
        border-radius: 0px;
    }

    .title_container {
        width: 90%;
        margin-left: 5%;
        margin-top: 0px;
    }

    .title_container > h1 {
        font-size: 2.5em;
        margin-bottom: 10px;
    }
    
    .data_container {
        width: 90%;
        margin-left: 5%;
        margin-top: 20px;
    }

    .content_container {
        width: 80%;
        margin-left: 10%;
        margin-top: 40px;
    }

    .author_container {
        width: 80%;
        margin-left: 10%;
    }
}

@media (prefers-color-scheme: dark) {
    .title_container > h1 {
        color: #dadada;
    }
    
    .data_container {
        color: #dadada;
    }

    .data_container > span{
        color: #dadada;
    }

    .content_container > h2{
        color: #dadada;
    }

    .content_container > h3{
        color: #dadada;
    }

    .content_container > p{
        color: #dadada;
    }

    .author_container {
        color: #dadada;
    }
}