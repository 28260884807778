.main-footer {
  width: 100%;
  height: 42px;
  background-color: #368FE7;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-footer-text {
  color: white;
}

@media (prefers-color-scheme: dark) {
  .main-footer {
    background-color: #2b2b2e;
  }
}