.section-title {
  width: 60%;
  margin-left: 20%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid lightgrey;
  position: relative;
}

.section-title-icon {
  height: 40px;
}

.section-title-title {
  margin: 0px;
  margin-left: 60px;
  margin-bottom: 10px;
  bottom: 0px;
  font-size: 25px;
  color: #333;
  position: absolute;
}

@media screen and (max-width:600px) {
  .section-title {
    width: 90%;
    margin-left: 5%;
  }
}

@media (prefers-color-scheme: dark) {
  .section-title {
    border-color: #2b2b2e;
  }

  .section-title-title {
    color: #dadada;
  }
}