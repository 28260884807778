.project-loading-container {
  width: 60%;
  margin-left: 20%;
  padding-top: 180px;
  margin-top: -160px;
}

.projects-section {
  width: 60%;
  margin-left: 20%;
  padding-top: 180px;
  margin-top: -160px;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.project-card {
  border: 1px solid lightgray;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
}

.project-labels {
  width: 90%;
  margin-top: 10px;
  margin-left: 5%;
  margin-right: 30px;
  display: flex;
  flex-direction: row;
}

.project-title {
  margin: 0px;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  text-align: left;
  font-size: 25px;
  color: #333;
}

.project-description {
  margin-left: 30px;
  margin-right: 30px;
  text-align: justify;
  font-size: 16px;
  line-height: 24px;
  color: #555;
  flex-grow: 1;
}

.project-buttons {
  width: 100%;
  min-height: 10px;
  margin-bottom: 30px;
  text-align: center;
}

.project-button {
  /* margin-right: 30px; */
  text-decoration: none;
  color: #0573e1;
}

.project-image {
  width: 100%;
  justify-self: flex-end;
}

.project-card-plus {
  width: 100%;
  height: 200px;
  margin-top: 10px;
  border: 1px solid lightgray;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
}

.project-plus {
  color: #0573e1;
  font-size: 150px;
  font-weight: bold;
  margin-top: -50px;
}

.project-plus-text {
  color: #0573e1;
  font-size: 20px;
}

@media screen and (max-width:600px) {
  .projects-section {
    width: 100%;
    margin-left: 0%;
    padding-top: 180px;
    margin-top: -160px;
  }

  .projects-container {
    width: 90%;
    margin-left: 5%;
    padding-top: 0px;
    margin-top: 0px;
    grid-template-columns: repeat(1, 1fr);
  }

  .project-card-plus {
    width: 90%;
    margin-left: 5%;
  }
}

@media (prefers-color-scheme: dark) {
  .project-card {
    background-color: #2b2b2e;
    border-color: #2b2b2e;
  }

  .project-title {
    color: #dadada;
  }
  
  .project-description {
    color: #dadada;
  }

  .project-button {
    color: #368fe7;
  }
  
  .project-card-plus {
    background-color: #2b2b2e;
    border-color: #222;
  }
  
  .project-plus {
    color: #368fe7;
  }

  .project-plus-text {
    color: #368fe7;
  }
}