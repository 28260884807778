.label {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 10px;
  display: inline-block;
  background-color: #368FE7;
  color: white;
  font-weight: bold;
  font-size: 15px;
}

@media (prefers-color-scheme: dark) {
  .label {
    background-color: #1f2023;
  }
}